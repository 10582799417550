import * as t from "io-ts";
import { validateAndDecode } from "../../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";
import { useQueryClient } from "@tanstack/react-query";

const createUrl = (patientId: string) =>
    `FeedbackTest/GetFeedbackTestStates?patientId=${patientId}`;

const boundedContext = "FeedbackTest";

const useFeedbackTestStateQuery = (patientId: string) => {
    return useBaseQuery<FeedbackTestState>(
        {
            url: createUrl(patientId),
            boundedContext,
            notifyOnChangeOn: patientId,
            validate: result => validateAndDecode(FeedbackTestStateIO, result),
        },
        { enabled: !!patientId }
    );
};

const useSetLocalFeedbackTestState = () => {
    const queryClient = useQueryClient();
    return (
        hearingAidId: string,
        patientId: string,
        dismissibleError: FeedbackTestDismissibleError,
        error: FeedbackTestError | null,
        state?: CalibrationState
    ) => {
        const queryKey = [boundedContext, patientId, createUrl(patientId)];
        const queryData = queryClient.getQueryData<FeedbackTestState>(queryKey);
        const hearingAid = queryData?.find(x => x.hearingAidId === hearingAidId);

        if (!queryData || !hearingAid) return;

        queryClient.setQueryData<FeedbackTestState | undefined>(
            queryKey,
            queryData.map(ha =>
                ha.hearingAidId === hearingAidId
                    ? { ...ha, dismissibleError, error, state: state ?? ha.state }
                    : ha
            )
        );
    };
};

const CalibrationStateIO = t.union([
    t.literal("notReady"),
    t.literal("notCalibrated"),
    t.literal("inProgress"),
    t.literal("calibrated"),
]);

const FeedbackTestDismissibleErrorIO = t.union([
    t.literal("none"),
    t.literal("tooMuchNoise"),
    t.literal("eepromWriteFailed"),
    t.literal("overflow"),
    t.literal("undefined"),
    t.literal("generalBackendError"),
    t.literal("pollingExpired"),
]);

const FeedbackTestErrorIO = t.union([
    t.literal("none"),
    t.literal("masterProgramRequired"),
    t.null,
]);

const HearingAidStateIO = t.type({
    hearingAidId: t.string,
    state: CalibrationStateIO,
    dismissibleError: FeedbackTestDismissibleErrorIO,
    error: FeedbackTestErrorIO,
});
const FeedbackTestStateIO = t.array(HearingAidStateIO);

export type FeedbackTestState = t.TypeOf<typeof FeedbackTestStateIO>;
export type CalibrationState = t.TypeOf<typeof CalibrationStateIO>;
export type FeedbackTestDismissibleError = t.TypeOf<typeof FeedbackTestDismissibleErrorIO>;
export type FeedbackTestError = t.TypeOf<typeof FeedbackTestErrorIO>;
export { useFeedbackTestStateQuery, useSetLocalFeedbackTestState };
